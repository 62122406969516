<template>
    <BCard no-body>
      <BCardHeader class="align-items-center d-sm-flex py-sm-0">
        <BCardTitle class="mb-0 flex-grow-1">Member Registration Trends – Daily Overview</BCardTitle>
        <div class="flex-shrink-0">
          <div class="dropdown card-header-dropdown">
            <BDropdown variant="link" class="card-header-dropdown" toggle-class="text-reset dropdown-btn" no-caret
              menu-class="dropdown-menu-end" aria-haspopup="true"
              :offset="{ alignmentAxis: -30, crossAxis: 0, mainAxis: 0 }">
              <template #button-content>
                <span class="fw-semibold text-uppercase fs-12">Sort by: </span><span
                  class="text-muted">1 Week</span>
              </template>
              <BDropdownItem>1 Month</BDropdownItem>
              <BDropdownItem>1 Year</BDropdownItem>
            </BDropdown>
          </div>
        </div>
      </BCardHeader>
  
      <BCardBody class="pb-0">
        <div class="d-flex justify-content-between">
            <div class="">
                <p class="mb-1 text-muted">Total Members</p>
                <p class="fw-bold">{{ $filters.number_format(data.total_members) }}</p>
            </div>
            <div class="">
                <p class="mb-1 text-muted">Total Today</p>
                <p class="fw-bold">{{ $filters.number_format(data.total_today) }}</p>
            </div>
            <div class="">
                <p class="mb-1 text-muted">Total Yesterday</p>
                <p class="fw-bold">{{ $filters.number_format(data.total_yesterday) }}</p>
            </div>
        </div>
        <apexchart class="apex-charts" height="300" :options="areaChartOptions" :series="seriesData" />
      </BCardBody>
    </BCard>
</template>
<script>

import VueApexCharts from "vue3-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            isLoading: false,
            areaChartOptions: {
                chart: {
                    id: "member-registration-report",
                    type: "area",
                    stacked: true,
                    height: 300,
                    toolbar: {
                        show: false,
                    },
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.3,
                        opacityTo: 0.9,
                        stops: [0, 90, 100]
                    }
                },
                markers: {
                    size: 2,
                    colors: ["#ffffff"],
                    strokeColor: "#025a3d",
                    strokeWidth: 1
                },
                legend: {
                    offsetY: 5
                },
                xaxis: {
                    type: "datetime"
                },
                grid: {
                    padding: {
                        bottom: 10
                    }
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                        style: {
                            cssClass: '',
                        },
                        formatter: function(val) {
                            return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
                        }
                    }
                },
                colors: ["#025a3d"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [3],
                    curve: "smooth"
                }
            },
            data: {
                total_members: 0,
                total_today: 0,
                total_yesterday: 0,
                series: []
            }
        };
    },
    computed:{
        seriesData(){
            return [{
                name: "Total Members",
                data: this.data.series.map((i) => {
                    return [i.day, i.members_count]
                })
            }]
        }
    },
    methods:{
        fetchItem(showLoader = false){
            this.isLoading = showLoader
            this.$http.get('/analytics/daily-registrations')
            .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.data = response.data.data
                }
            })
        },
    },
    mounted(){
       this.fetchItem()
    }
};
</script>


