
<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">{{ title }}</h5>
      </div>
      <div class="card-body py-0 px-lg-3">
        <div class="table-responsive mb-0 loading-viewport">
          <is-loading v-if="isLoading" :box="true" />
          <b-table striped hover ref="selectableTable" :items="pageData.data"
            :fields="columns" responsive="sm" align="middle">
            <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
              v-slot:[`head(${column.key})`] >
                <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                d-flex align-items-center">
                  {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                </div>
            </template>
            <template v-slot:cell(logo_image)="data">
              <div style="min-width:5rem;">
                <div class="avatar-group">
                  <div class="avatar-group-item material-shadow-none"
                     v-b-tooltip.hover title="Group logo">
                    <div class="avatar bg-transparent cursor-pointer">
                      <img @click="showProfileImg(data.item.logo)" :src="absoluteUrl(data.item.logo)" alt="" class="w-100 h-100 rounded-circle img-fluid" />
                    </div>
                  </div>
                  <div class="avatar-group-item material-shadow-none "
                     v-b-tooltip.hover :title="`${data.item.head_name}'s image`">
                    <div class="avatar bg-transparent cursor-pointer">
                      <img  @click="showProfileImg(data.item.head_image)" :src="absoluteUrl(data.item.head_image)" alt="" class="w-100 h-100 rounded-circle img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:cell(group_name)="data">
              <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                {{ data.item.group_name }}
              </div>
            </template>
            <template v-slot:cell(member_count)="data">
              <div style="min-width:4rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                <h6 class="mb-0">{{ data.item.member_count }}</h6>
              </div>
            </template>
          </b-table>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
  </div>
  </template>
  
<script>

import IsLoading from '@/components/IsLoading.vue';
import { commonSortingMixin } from '@/mixins/commonSortingMixin';
import { commonPaginationMixin } from '@/mixins/commonPaginationMixin';

export default {
  name: "groups-with-top-members",
  mixins: [
    commonSortingMixin,
    commonPaginationMixin,
  ],
  props:{
    title:{
      default: 'Groups with top members'
    }
  },
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          key: "logo_image",
          label: "Logo/Image",
        },
        {
          key: "group_name",
          label: "Group Name",
          sortable: true,
        },
        {
          key: "member_count",
          label: "Members",
          sortable: true
        },
      ],
      sortBy: {
        sort_column: "member_count",
        sort_direction: "desc"
      },
      pageData:{
        data: []
      },
      perPage: 5
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/groups/${id}`});
    },
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      this.isLoading = true
      this.$http.get(`/analytics/members-per-group?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    initializeAll(){
    }
  },
  mounted() {
    this.fetchItems()
  },
}

</script>
  
  