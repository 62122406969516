<template>
    <BCard no-body>
      <BCardHeader class="align-items-center d-flex py-0">
        <BCardTitle class="mb-0 flex-grow-1">Voters Analysis</BCardTitle>
        <div class="flex-shrink-0">
          <div class="dropdown card-header-dropdown">
            <BDropdown variant="link" class="card-header-dropdown" toggle-class="text-reset dropdown-btn" no-caret
              menu-class="dropdown-menu-end" aria-haspopup="true"
              :offset="{ alignmentAxis: -30, crossAxis: 0, mainAxis: 0 }">
              <template #button-content><span class="fw-semibold text-uppercase fs-12">Sort by: </span><span
                  class="text-muted">2023 </span>
              </template>
              <BDropdownItem>2023</BDropdownItem>
            </BDropdown>
          </div>
        </div>
      </BCardHeader>
  
      <BCardBody class="pb-0">
        <apexchart class="apex-charts" height="341" dir="ltr" :series="series" :options="chartOptions"></apexchart>
      </BCardBody>
    </BCard>
  </template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    components:{
      apexchart: VueApexCharts,
    },
    props:{
        voters:{
            type: Object,
            default:() =>({
                total: 0,
                voted: 0,
                not_voted: 0,
                total_checked: 0
            })
        },
    },
    data() {
        return {
        series: [
            {
                name: "Total Checked",
                data: [ this.voters.total_checked ],
            },
            {
                name: "Voted",
                data: [ this.voters.voted ],
            },
            {
                name: "Not Voted",
                data: [ this.voters.not_voted ],
            },
        ],
        chartOptions: {
            chart: {
                type: "bar",
                height: 341,
                toolbar: {
                    show: true,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "90%",
                    borderRadius: 0,
                    borderRadiusApplication: 'around',
                    borderRadiusWhenStacked: 'last',
                },
            },
            stroke: {
                show: true,
                width: 10,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [""],
                axisTicks: {
                    show: false,
                    borderType: "solid",
                    color: "#78909C",
                    height: 6,
                    offsetX: 0,
                    offsetY: 0,
                },
                title: {
                    text: "Total Evaluated Voters 2023",
                    offsetX: 0,
                    offsetY: -30,
                    style: {
                    color: "#78909C",
                    fontSize: "12px",
                    fontWeight: 400,
                    },
                },
                crosshairs: {
                    show: false // <--- HERE
                }
            },
            yaxis: {
            labels: {
                formatter: function (val) {
                    return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
                },
            },
            tickAmount: 4,
            min: 0,
            },
            fill: {
                opacity: 1,
            },
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                fontWeight: 500,
                offsetX: 0,
                offsetY: -14,
                itemMargin: {
                    horizontal: 8,
                    vertical: 0,
                },
                markers: {
                    width: 10,
                    height: 10,
                },
            },
            colors: ["#000000", "#0ab39c", "#f56900"]
        }
        };
    },
    watch: {
        voters: {
            immediate: true,
            deep: true,
            handler(newMembers) {
                this.series = [
                    {
                        name: "Total Checked",
                        data: [newMembers.total_checked],
                    },
                    {
                        name: "Voted",
                        data: [newMembers.voted],
                    },
                    {
                        name: "Not Voted",
                        data: [newMembers.not_voted],
                    },
                ];
            },
        },
    },
};
</script>